import React from 'react'

const strokeStyle = { vectorEffect: 'non-scaling-stroke' }

const BedIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="black" width="30px" height="30px">
        <g>
            <rect fill="none" height="24" width="24"/>
        </g>
            <g>
            <g>
                <rect fill="none" height="3" width="5" x="6" y="7"/>
            <rect fill="none" height="3" width="5" x="13" y="7"/>
            <path d="M20,10V7c0-1.1-0.9-2-2-2H6C4.9,5,4,5.9,4,7v3c-1.1,0-2,0.9-2,2v5h1.33L4,19h1l0.67-2h12.67L19,19h1l0.67-2H22v-5 C22,10.9,21.1,10,20,10z M11,10H6V7h5V10z M18,10h-5V7h5V10z"/>
            </g>
            </g>
    </svg>
)

export default BedIcon
